import React, { useMemo, useEffect, Fragment } from 'react';

import { Col, Row, Text, Button, Container } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import Picture from '@soluto-design/picture';
import TitleSection from '../TitleSection';

import Prism from 'prismjs';

import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';
import './prism.css';

import Video from '../../molecules/Video';

import styles from './styles.module.scss';

export interface BlockType {
    type: string;
    [x: string]: any;
}

export interface EnrichedTextRowProps {
    title?: string;
    text?: string;
    media?: BlockType[];
    mediaHalf?: BlockType[];
}

interface EnrichedTextTags {
    [x: string]: string[];
}

export interface EnrichedTextProps {
    rows?: EnrichedTextRowProps[];
    title?: string;
    subtitle?: string;
    tags?: EnrichedTextTags;
    onlyText?: boolean;
}

const getVideoParams = (video: any) => {
    let params: any = {};
    if (video.external && video.provider) {
        params.externalVideo = video;
    } else if (video.file?.rawCloudinaryData?.url) {
        params.video = {
            isCloudinary: true,
            file: {
                publicURL: video.file.rawCloudinaryData.url,
                extension: video.file.rawCloudinaryData.format,
            },
        };
    } else if (video.file) {
        params.video = video;
    } else if (video.url) {
        params.video = {
            isCloudinary: video.isCloudinary || false,
            file: {
                publicURL: video.url,
                extension: video.url.split('.').pop() ?? 'mp4',
            },
        };
    }

    return params;
};

function getImageSizes(halfCol: boolean = false) {
    return halfCol
        ? '(max-width: 414px) 748px, 1320px'
        : '(max-width: 414px) 748px, (max-width: 1023px) 1320px, 3200px';
}

function getIframeProps(src: string): any {
    const isSpotify = src.includes('open.spotify.com');
    const props: any = {
        style: {
            width: '100%',
            overflow: 'hidden',
            height: '190px',
        },
        frameBorder: 0,
        src,
    };

    if (isSpotify) {
        props.style.height = '152px';
        props.style.borderRadius = '12px';
        props.allowFullScreen = true;
        props.allow =
            'autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture';
        props.loading = 'lazy';
        if (!src.includes('/embed/')) {
            props.src = src.replace(
                'https://open.spotify.com',
                'https://open.spotify.com/embed',
            );
        }
        if (!src.includes('&theme=0')) {
            props.src += '&theme=0';
        }
    }

    return props;
}

function printSingleCol(block: BlockType, halfCol: boolean = false) {
    switch (block?.type) {
        case 'image':
            return block?.image ? (
                block.image?.caption ? (
                    <div className={styles.media}>
                        <Picture
                            {...(!!block.image?.file?.gatsbyImageData && {
                                sizes: getImageSizes(halfCol),
                            })}
                            src={block.image.file}
                            alt={block.image.caption}
                        />
                        <Text component="p" className="font-c200-regular pt-md">
                            {block.image.caption}
                        </Text>
                    </div>
                ) : (
                    <Picture
                        {...(!!block.image?.file?.gatsbyImageData && {
                            sizes: getImageSizes(halfCol),
                        })}
                        classes={{
                            wrapper: styles.media,
                        }}
                        src={block.image.file}
                        alt=""
                    />
                )
            ) : null;
        case 'video':
            return block?.video ? (
                <Video
                    className={styles.media}
                    id={block.id}
                    {...getVideoParams(block.video)}
                />
            ) : null;
        case 'embed':
            return (
                <iframe
                    title="Embed"
                    className={styles.media}
                    {...getIframeProps(block.content)}
                />
            );

        case 'code':
            return (
                <pre className={styles.media}>
                    <code className={cls(`language-${block.language}`)}>
                        {block.content
                            .replace(/<br\s*\/?>/gi, '\n')
                            .replace(/&nbsp;/gi, ' ')
                            .replace(/&amp;/gi, '&')}
                    </code>
                </pre>
            );
        case 'button':
            return (
                <Button
                    variant="secondary"
                    size="text"
                    href={block.href}
                    rightIcon="arrow-corner--type-right-up">
                    {block.content}
                </Button>
            );
        case 'callout':
            return (
                <div className={cls(styles.callout, styles.media)}>
                    {block.blocks.map((block: BlockType, i) => (
                        <Fragment key={`callout-${i}`}>
                            {printSingleCol(block, halfCol)}
                        </Fragment>
                    ))}
                </div>
            );
        default:
            return null;
    }
}

function printBlocks(rows: EnrichedTextRowProps[], onlyText?: boolean) {
    return rows.map((row, i) => (
        <Fragment key={`row-${i}`}>
            {!!row.title && (
                <Col
                    col={
                        onlyText
                            ? {
                                  md: 7,
                                  lg: 9,
                                  hd: 8,
                              }
                            : {
                                  md: 3,
                                  lg: 3,
                                  hd: 3,
                              }
                    }
                    className={cls(styles.wrapper, styles.title)}
                    dangerouslySetInnerHTML={{ __html: row.title }}
                />
            )}
            {row.text || row.title ? (
                <Col
                    col={
                        onlyText
                            ? {
                                  md: 7,
                                  lg: 9,
                                  hd: 8,
                              }
                            : {
                                  md: 5,
                                  lg: 7,
                              }
                    }
                    className={cls(
                        styles.wrapper,
                        'font-b500-regular',
                        styles.text,
                    )}
                    {...(!onlyText && {
                        offset: row.title ? { lg: 2 } : { md: 3, lg: 5 },
                    })}
                    dangerouslySetInnerHTML={{ __html: row.text || '' }}
                />
            ) : null}
            {!!row.media?.length && (
                <Col
                    {...(row.media.length === 1 &&
                        row.media[0].type === 'embed' && {
                            col: {
                                md: 5,
                                lg: 7,
                            },
                            offset: {
                                md: 3,
                                lg: 5,
                            },
                        })}
                    className={cls(styles.wrapper, styles.mediaWrapper)}>
                    {row.media.map((media, j) => (
                        <Fragment key={`media-${i}-${j}`}>
                            {printSingleCol(media)}
                        </Fragment>
                    ))}
                </Col>
            )}
            {!!row.mediaHalf?.length &&
                row.mediaHalf.map((media, j) => (
                    <Col
                        key={`media-half-${i}-${j}`}
                        className={cls(
                            styles.wrapper,
                            styles.mediaWrapper,
                            styles.mediaWrapperHalf,
                        )}
                        col={{ md: 4 }}
                        offset={row.mediaHalf?.length === 1 ? { md: 2 } : {}}>
                        {printSingleCol(media, true)}
                    </Col>
                ))}
        </Fragment>
    ));
}

export default function EnrichedText({
    rows = [],
    title,
    subtitle,
    tags,
    onlyText,
}: EnrichedTextProps) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const finalBlocks = useMemo(() => {
        let newRows: any[] = [];

        newRows = [...rows];

        return printBlocks(newRows, onlyText);
    }, [rows, onlyText]);

    return (
        <section>
            {!!title && (
                <TitleSection
                    size="large"
                    uppercase
                    title={title}
                    {...(!!subtitle && { subtitle })}
                />
            )}
            <Container
                className={cls(
                    'pt-xxxl pb-huge',
                    onlyText && styles['only-text'],
                )}>
                <Row>{finalBlocks}</Row>
            </Container>
        </section>
    );
}
